import React, { useEffect } from 'react'
import { navigate } from 'gatsby';

import s from './footer.module.css'
const netlifyIdentity = require("netlify-identity-widget");

export default ({ children }) => {

  useEffect(() => {
    netlifyIdentity.init({container: "#widgets"});
    netlifyIdentity.on("login", () => goToStarfsfolkPage());
    netlifyIdentity.on("logout", () => goToNotifyHomePage());
  }, [netlifyIdentity, goToStarfsfolkPage, goToNotifyHomePage]);

  const goToStarfsfolkPage = () => {
    navigate('/starfsfolk');
  }

  const goToNotifyHomePage = () => {
    navigate('/');
  }

  return (
    <div className={s.footer}>
      {children}

      <div className={s.login}>
        <div data-netlify-identity-button />
      </div>
    </div>
  );
}