import React from 'react'
import { Link } from 'gatsby'
import s from './icon.module.css'

export default ({ children, logoUrl, link }) => (
  <div className={s.icon}>
    <Link to={link || '/starfsfolk'}>
      <img src={logoUrl || require('../assets/images/icon.png')} alt="icon"/>
    </Link>
  </div>
)
