import React from 'react'
import { Link } from 'gatsby'

import s from './button.module.css'

export default ({ children, giant, to, onClick, inWrapper, fill }) => {

  if (to) {
    return (
      <div className={[s.buttonWrapper,
        inWrapper ? s.inWrapper : ''
        ].filter(x => !!x).join(' ')}>
        <Link className={[
          s.button,
          fill ? s.fill : '',
          giant ? s.giant : ''
        ].filter(x => !!x).join(' ')} to={to}>
          {children}
        </Link>
      </div>
    )
  }

  return (
    <div className={[s.buttonWrapper,
      inWrapper ? s.inWrapper : ''
      ].filter(x => !!x).join(' ')}>
      <button className={[
        s.button,
        giant ? s.giant : '',
        fill ? s.fill : ''
      ].filter(x => !!x).join(' ')} onClick={onClick}>
        {children}
      </button>
    </div>
    )
}
