import React from 'react';
import { Helmet } from 'react-helmet';
import base from './base.css';

import Header from './header';
import Footer from './footer';
import Icon from './icon';
import Button from './button';

import { helmet } from '../utils/helmet';

class Template extends React.Component {
  render() {
    const { children, noHeader, logo, logoLink, changeListType, changeListTypeText } = this.props
    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <div>
        <Helmet {...helmet} />
        {!noHeader &&
          <Header>
            <Icon logoUrl={logo} link={logoLink} />
            {changeListType && changeListTypeText &&
              <Button onClick={changeListType}>
                {changeListTypeText}
              </Button>
            }
          </Header>
        }
        {children}
        <div id="widgets"></div>
      </div>
    )
  }
}

export default Template
