import React, { useEffect } from 'react'
import { navigate } from 'gatsby';

import s from './header.module.css'

export default ({ children }) => {

  return (
    <div className={s.header}>
      {children}
    </div>
  );
}